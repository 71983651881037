import { pdfjs } from 'react-pdf'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import SvgIcon from '@mui/material/SvgIcon'
import Grid from '@mui/material/Grid'
import { Trash01 } from '@untitled-ui/icons-react'
import FileThumbnailIcon from './FileThumbnailIcon'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material'

// Set up the worker for PDF rendering
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface FileThumbnailProps {
  /**
   * Page number to render
   */
  pageNumber: number
  /**
   * Width of the thumbnail
   */
  width?: number
  /**
   * File to render
   */
  file?: File
  /**
   * Whether to show the title
   */
  showTitle?: boolean
  /**
   * URL of the file
   */
  url?: string | null
  /**
   * Whether the thumbnail is loading (not used)
   */
  _isLoading?: boolean
  /**
   * Callback when the remove button is clicked
   * @param {File} file The file to remove
   */
  onRemove?: (file?: File) => void
  /**
   * to manage local url during loading step
   */
  flagLocal?: boolean

  fileType?: string
}

/**
 * The FileThumbnail component renders a thumbnail for a file (PDF or image).
 * It supports displaying a page from a PDF file or an image, and includes an option to remove the file.
 *
 * @param {FileThumbnailProps} props - The properties passed to the component.
 * @returns {JSX.Element} The rendered FileThumbnail component.
 */
export const FileThumbnail: FC<FileThumbnailProps> = ({
  pageNumber,
  width,
  file,
  onRemove,
  url,
  _isLoading,
  showTitle = true,
  flagLocal = false,
  fileType,
}) => {
  // State to manage the loading status of the thumbnail.
  const [isLoading, setIsLoading] = useState(_isLoading)

  // Effect to update the loading status when the _isLoading prop changes.
  useEffect(() => {
    setIsLoading(_isLoading)
  }, [_isLoading])

  // Generate a temporary URL for local files, if necessary.
  const url_temp =
    file && URL && URL.createObjectURL ? URL.createObjectURL(file) : ''

  const cardProps: SxProps<Theme> & { height?: string } = {
    px: 4,
    py: 2,
    borderRadius: 1.5,
    userSelect: 'none',
    '&.MuiPaper-elevation1': {
      boxShadow: 0,
    },
    width: width,
    '&:hover': {
      backgroundColor: (theme) =>
        theme.palette.mode === 'dark' ? 'neutral.700' : 'neutral.50',
    },
    backgroundColor: (theme) =>
      theme.palette.mode === 'dark' ? 'neutral.800' : 'background.paper',
  }

  if (!showTitle) {
    cardProps.height = '100%'
  }

  return (
    <Card elevation={1} sx={cardProps} data-testid="file-thumbnail-container">
      <Box
        sx={{
          mb: 1,
          display: 'flex',
          justifyContent: 'center',
          height: !file || !showTitle ? '100%' : 'inherit',
          alignItems: 'center',
        }}
      >
        <FileThumbnailIcon
          file={file}
          fileType={fileType}
          flagLocal={flagLocal}
          isLoading={isLoading}
          pageNumber={pageNumber}
          setIsLoading={setIsLoading}
          url_temp={url_temp}
          url={url}
          width={width}
        />
      </Box>
      {file && showTitle && (
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={8}>
            <Typography
              variant="caption"
              sx={{
                height: '40px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
              }}
            >
              {/* eslint-disable-next-line */}
              {file!.name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={1}>
            <IconButton
              edge="end"
              onClick={(e) => {
                e.stopPropagation()
                onRemove?.(file)
              }}
              data-testid="file-input-remove-button"
            >
              <SvgIcon>
                <Trash01 />
              </SvgIcon>
            </IconButton>
          </Grid>
        </Grid>
      )}
    </Card>
  )
}

export default FileThumbnail
