import { Document, Page } from 'react-pdf'
import CircularProgress from '@mui/material/CircularProgress'
import ImageWithChevron from '../../ImageWithChevron'
import ExcelFile from '@/icons/excel-file'
import ConversationFile from '@/icons/conversation-file'
import JsonFile from '@/icons/json-file'
import { nabuColors } from '@/theme/colors'
import { Dispatch, SetStateAction } from 'react'

export interface Props {
  file?: File
  url?: string | null
  isLoading?: boolean
  setIsLoading: Dispatch<SetStateAction<boolean | undefined>>
  pageNumber?: number
  width?: number
  fileType?: string
  flagLocal?: boolean
  url_temp: string
}

const FileThumbnailIcon = ({
  file,
  fileType,
  flagLocal,
  isLoading,
  pageNumber,
  setIsLoading,
  url_temp,
  url,
  width,
}: Props) => {
  return file && url?.endsWith('.pdf') ? (
    <Document file={file} onLoadSuccess={() => setIsLoading(false)}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Page
          pageNumber={pageNumber}
          width={width}
          renderAnnotationLayer={false}
          renderTextLayer={false}
          renderMode="canvas"
        />
      )}
    </Document>
  ) : file?.name.includes('conversation') || fileType === 'conversation' ? (
    <ConversationFile
      sx={{ width: file ? 128 : 256, height: file ? 128 : 256 }}
      fill={nabuColors.greenCyan}
    />
  ) : url?.endsWith('.json') || fileType === 'json' ? (
    <JsonFile
      sx={{ width: file ? 128 : 256, height: file ? 128 : 256 }}
      fill={nabuColors.greenCyan}
    />
  ) : file?.type?.includes('spreadsheetml') ||
    file?.type?.includes('ms-excel') ||
    fileType === 'excel' ? (
    <ExcelFile
      sx={{
        width: file ? 96 : 160,
        height: file ? 96 : 160,
        display: 'inline-block',
      }}
      fill={nabuColors.greenCyan}
    />
  ) : isLoading ? (
    <CircularProgress />
  ) : (
    <ImageWithChevron
      url={url && !flagLocal ? url : url_temp}
      flagLocal={flagLocal}
    />
  )
}

export default FileThumbnailIcon
