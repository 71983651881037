import {
  ComponentProps,
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import DialogContentText from '@mui/material/DialogContentText'
import Typography from '@mui/material/Typography'
import useTranslate from '@/hooks/use-translate'

interface ConfirmModalProps {
  open: boolean
  title?: string
  text?: string
  cancelButtonText?: string
  confirmButtonText?: string
  onConfirm: () => void
  onCancel: () => void
}

const ConfirmModal: FC<PropsWithChildren<ConfirmModalProps>> = (props) => {
  const {
    open,
    title,
    text,
    onConfirm,
    onCancel,
    cancelButtonText,
    confirmButtonText,
  } = props

  const { t: tCommon } = useTranslate('common')

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={'xs'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ padding: '20px' }}>
        {title ? title : 'Default title'}
      </DialogTitle>
      {text && (
        <DialogContent sx={{ padding: '20px' }}>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="caption">
              {text ? text : 'Default text'}
            </Typography>
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions sx={{ padding: '20px' }}>
        <Button
          color="inherit"
          size="small"
          type="button"
          onClick={onCancel}
          sx={{ textTransform: 'none' }}
          data-testid="confirm-modal-cancel-button"
        >
          {cancelButtonText ? cancelButtonText : tCommon('cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={onConfirm}
          sx={{ textTransform: 'none' }}
          data-testid="confirm-modal-confirm-button"
        >
          {confirmButtonText ? confirmButtonText : tCommon('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

type Params = Partial<
  Omit<ComponentProps<typeof ConfirmModal>, 'open' | 'onConfirm' | 'onCancel'>
>

const defaultFunction = (p?: Params) => Promise.resolve(true) // En l'absence de contexte, on renvoie true directement

const defaultValue = {
  confirmRef: {
    current: defaultFunction,
  },
}

const ConfirmContext = createContext(defaultValue)

// On devra entourer notre application avec ce context provider
export function ConfirmContextProvider({ children }: PropsWithChildren) {
  const confirmRef = useRef(defaultFunction)
  return (
    <ConfirmContext.Provider value={{ confirmRef }}>
      {children}
      <ConfirmDialogWithContext />
    </ConfirmContext.Provider>
  )
}

function ConfirmDialogWithContext() {
  const [open, setOpen] = useState(false)
  const [props, setProps] = useState<undefined | Params>()
  const resolveRef = useRef((v: boolean) => {})
  const { confirmRef } = useContext(ConfirmContext)
  confirmRef.current = (props) =>
    new Promise((resolve) => {
      setProps(props)
      setOpen(true)
      resolveRef.current = resolve
    })

  const onConfirm = () => {
    resolveRef.current(true)
    setOpen(false)
  }

  const onCancel = () => {
    resolveRef.current(false)
    setOpen(false)
  }
  return (
    <ConfirmModal
      onConfirm={onConfirm}
      onCancel={onCancel}
      open={open}
      {...props}
    />
  )
}

// Ce hook nous permettra d'accéder à la fonction confirm
export function useConfirm() {
  const { confirmRef } = useContext(ConfirmContext)
  return {
    confirm: useCallback(
      (p: Params) => {
        return confirmRef.current(p)
      },
      [confirmRef]
    ),
  }
}
