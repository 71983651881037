/* eslint-disable prettier/prettier */

import { tokens } from '@/locales/tokens/index'

export const en = {
  [tokens.common.welcome]: 'Welcome',
  [tokens.common.welcomeLogin]: 'Welcome to Nabu',
  [tokens.common.welcomeLoginText]:
    'Nabu is the customs co-pilot that allows you to access, manage and efficiently execute your customs formalities and data.',
  [tokens.common.login]: 'Log in',
  [tokens.common.loginError]:
    'Oops, it seems that the email or password you entered is incorrect. Please double-check and try again.',
  [tokens.common.emailSendError]:
    'Oops, it seems that the email sending failed. Please try again.',
  [tokens.common.returnToLoginPage]: 'Return to the login page',
  [tokens.common.logout]: 'Log out',
  [tokens.common.noAccount]: "You don't have an account ?",
  [tokens.common.questionForgotPassword]: 'Forgot password ?',
  [tokens.common.forgotPassword]: 'Forgot password',
  [tokens.common.enterNewPassword]: 'Set a new password',
  [tokens.common.passwordConfirmation]: 'Confirm the password',
  [tokens.common.forgotPasswordText]:
    'Enter the e-mail address associated with your account',
  [tokens.common.oldPasswordRequired]: 'The old password is required',
  [tokens.common.newPasswordRequired]: 'A new password is required',
  [tokens.common.warningRulesForNewPassword]:
    'The password must contain at least one lower case letter, one upper case letter, one number, one special character (@, $, !, %, *, ?, &) and be between 8 and 16 characters long.',
  [tokens.common.emailSent]: 'An email has been sent',
  [tokens.common.contactUs]: 'Contact us',
  [tokens.common.confirm]: 'Confirm',
  [tokens.common.noOptions]: 'No options',
  [tokens.common.validate]: 'Validate',
  [tokens.common.delete]: 'Delete',
  [tokens.common.cancel]: 'Cancel',
  [tokens.common.errorRenameFlowName]: 'The flowname could not be renamed',
  [tokens.common.continue]: 'Continue',
  [tokens.common.next]: 'Next',
  [tokens.common.back]: 'Back',
  [tokens.common.close]: 'Close',
  [tokens.common.create]: 'Create',
  [tokens.common.upload]: 'Upload',
  [tokens.common.sentDocuments]: 'Documents sent',
  [tokens.common.sentFolders]: 'Folders sent',
  [tokens.common.tableConsolidated]: 'Consolidated table',
  [tokens.common.tableNotConsolidated]: 'Unconsolidated table',
  [tokens.common.numberofLinesSent]: 'Total number of lines sent:',
  [tokens.common.send]: 'Send',
  [tokens.common.add]: 'Add',
  [tokens.common.languageChanged]: 'Language changed',
  [tokens.common.manager]: 'Manager',
  [tokens.common.guest]: 'Guest',
  [tokens.common.admin]: 'Administrator',
  [tokens.common.user]: 'User',
  [tokens.common.superadmin]: 'Nabu Staff',
  [tokens.common.mail]: 'Email address',
  [tokens.common.email]: 'Email',
  [tokens.common.mailHook]: 'Email Hook',
  [tokens.common.regenerateMailHook]: 'Regenerate mail hook',
  [tokens.common.mailHookDescription]:
    "Nabu e-mail address connected to your team's trainer folder inbox",
  [tokens.common.copy]: 'copy',
  [tokens.common.role]: 'Role',
  [tokens.common.date]: 'Date',
  [tokens.common.name]: 'Name',
  [tokens.common.member]: 'Member',
  [tokens.common.members]: 'Members',
  [tokens.common.administrator]: 'Administrator',
  [tokens.common.administrators]: 'Administrators',
  [tokens.common.sendInvite]: 'Send an invitation',
  [tokens.common.inviteMember]: 'Invite a member',
  [tokens.common.inviteMembers]: 'Invite members',
  [tokens.common.inviteAdministrator]: 'Invite an administrator',
  [tokens.common.inviteAdministrators]: 'Invite administrators',
  [tokens.common.inviteSomeAdministrators]: 'Invite some administrators',
  [tokens.common.atLeastOneInvitedPeople]: 'Invite at least one person',
  [tokens.common.maxOneInvitedPeople]: 'Invite maximum one admin',
  [tokens.common.sendInvitation]: 'Send invitation',
  [tokens.common.deleteInvitation]: 'Delete the invitation',
  [tokens.common.socialName]: 'Company name',
  [tokens.common.registrationNumber]: 'Registration number',
  [tokens.common.country]: 'Country',
  [tokens.common.organization]: 'Organization',
  [tokens.common.organizationAndEstablishments]: 'Organization & Establishment',
  [tokens.common.somethingWentWrong]: 'Something went wrong...',
  [tokens.common.general]: 'General',
  [tokens.common.lastName]: 'Last name',
  [tokens.common.firstName]: 'First name',
  [tokens.common.function]: 'Role',
  [tokens.common.preferences]: 'Preferences',
  [tokens.common.password]: 'Password',
  [tokens.common.position]: 'Position',
  [tokens.common.language]: 'Language',
  [tokens.common.help]: 'Help',
  [tokens.common.active]: 'Active',
  [tokens.common.group]: 'Group',
  [tokens.common.groups]: 'Groups',
  [tokens.common.collaborators]: 'Collaborator',
  [tokens.common.update]: 'Update',
  [tokens.common.actions]: 'Actions',
  [tokens.common.parameters]: 'Parameters',
  [tokens.common.external]: 'External',
  [tokens.common.required]: 'required',
  [tokens.common.validEmail]: 'Must be a valid email',
  [tokens.common.emailRequired]: 'Email is required',
  [tokens.common.passwordRequired]: 'Password is required',
  [tokens.common.matchPasswordsRequired]: 'Passwords must match',
  [tokens.common.passwordConfirmationRequired]: 'Please confirm your password',
  [tokens.common.firstNameRequired]: 'First name is required',
  [tokens.common.lastNameRequired]: 'Last name is required',
  [tokens.common.roleRequired]: 'Role is required',
  [tokens.common.languageRequired]: 'Language is required',
  [tokens.common.organizationRequired]: 'Organization is required',
  [tokens.common.groupRequired]: 'Group is required',
  [tokens.common.socialNameRequired]: 'Social name is required',
  [tokens.common.registrationNumberRequired]: 'Registration number is required',
  [tokens.common.countryRequired]: 'Country is required',
  [tokens.common.affiliatedGroup]: 'To select the groups to affiliate with',
  [tokens.common.share]: 'Share',
  [tokens.common.registeredCustomsRepresentative]:
    'Registered Customs Representative',
  [tokens.common.customsManager]: 'Customs Manager',
  [tokens.common.customsTeamManager]: 'Customs Team Manager',
  [tokens.common.informationSystemsManager]: 'Information Systems Manager',
  [tokens.common.nabuAdministrator]: 'Nabu Administrator',
  [tokens.common.french]: 'French',
  [tokens.common.english]: 'English',
  [tokens.common.administrator]: 'Administrator',
  [tokens.common.basicInformation]: 'Basic information',
  [tokens.common.personalInformation]: 'Personal information',
  [tokens.common.companyInformation]: 'Company information',
  [tokens.common.organizationAndGroups]: 'Organization and Groups',
  [tokens.common.establishmentsAndGroups]: 'Establishments & groups',
  [tokens.common.settings]: 'Settings',
  [tokens.common.save]: 'Save',
  [tokens.common.changePassword]: 'Change password',
  [tokens.common.oldPassword]: 'Old password',
  [tokens.common.newPassword]: 'New password',
  [tokens.common.passwordChangeSuccess]: 'Password change successful',
  [tokens.common.fileName]: 'Declaration name',
  [tokens.common.generalInformation]: 'General Information',
  [tokens.common.offices]: 'Establishment',
  [tokens.common.edit]: 'Edit',
  [tokens.common.accessInformations]: 'Access informations',
  [tokens.common.deleteAccount]: 'Delete account',
  [tokens.common.deleteAccountWarning]:
    'Deleting your account and all your data is irreversible.',
  [tokens.common.name]: 'Name',
  [tokens.common.client]: 'Client',
  [tokens.common.date]: 'Date',
  [tokens.common.status]: 'Status',
  [tokens.common.actions]: 'Actions',
  [tokens.common.option10]: '10',
  [tokens.common.option20]: '20',
  [tokens.common.option50]: '50',
  [tokens.common.searchByCaseName]: 'Search by case name',
  [tokens.common.all]: 'All',
  [tokens.common.pending]: 'Pending',
  [tokens.common.from]: 'From',
  [tokens.common.to]: 'To',
  [tokens.common.view]: 'View',
  [tokens.common.folder]: 'Folder',
  [tokens.common.customDeclaration]: 'Custom Declaration',
  [tokens.common.billOfLading]: 'Bill of Lading',
  [tokens.common.exported]: 'Exported',
  [tokens.common.toTreat]: 'To Process',
  [tokens.common.archived]: 'Archived',
  [tokens.common.inProgress]: 'In Progress',
  [tokens.common.category]: 'Category',
  [tokens.common.searchByName]: 'Search for a folder',
  [tokens.common.layerName]: 'Layer name',
  [tokens.common.flowName]: 'Customer flow',
  [tokens.common.flowSearchPlaceholder]: 'Search for a flow',
  [tokens.common.flowTabName]: 'Flow name',
  [tokens.common.flowTabType]: 'Flow type',
  [tokens.common.declarationType]: 'Decl. type',
  [tokens.common.flowTabExporter]: 'Sender',
  [tokens.common.flowTabCreatedBy]: 'Created by',
  [tokens.common.flowTabDate]: 'Creation date',
  [tokens.common.flowDeleteOneTitle]: 'Deleting one flow',
  [tokens.common.flowDeleteOneText]:
    'Are you sure you want to delete this flow?',
  [tokens.common.flowDeleteMultipleTitle]: 'Deleting multiple flow',
  [tokens.common.flowDeleteMultipleText]:
    'Are you sure you want to delete these flow?',
  [tokens.common.flowHasBeenDeleted]: 'The flow has been deleted',
  [tokens.common.flowHasNotBeenDeleted]: 'The flow was not deleted',
  [tokens.common.teamName]: 'Team name',
  [tokens.common.office]: 'Office',
  [tokens.common.loggedIn]: 'Welcome back! You have successfully logged in.',
  [tokens.common.permissionError]:
    "Sorry, you don't have permission to access this page.",
  [tokens.common.permissionAndAccess]: 'Permission/Access',
  [tokens.common.fieldNotFound]: 'Field not found',
  [tokens.common.failToSave]: 'Fail to save',
  [tokens.common.team]: 'Team',
  [tokens.common.profil]: 'Profil',
  [tokens.common.security]: 'Security',
  [tokens.common.metrics]: 'Performance monitoring',
  [tokens.common.copiedToClipBoard]: 'Copied to clipboard',
  [tokens.common.setup]: 'Configuration',
  [tokens.common.setups]: 'Configurations',
  [tokens.common.addSetup]: 'Add configuration',
  [tokens.common.noSetup]: 'No configuration available',
  [tokens.common.noOptionAvailable]: 'No option available',
  [tokens.common.noSetupGroup]: 'No configuration available for this group',
  [tokens.common.editSetup]: 'Edit configuration',
  [tokens.common.createSetup]: 'Create configuration',
  [tokens.common.download]: 'Download',
  [tokens.common.file]: 'File',
  [tokens.common.files]: 'Files',
  [tokens.common.punctualCustomer]: 'Ponctual customer',
  [tokens.common.regularCustomer]: 'Regular customer',
  [tokens.common.creationOfNewFolder]: 'Creation of new declaration',
  [tokens.common.emailFrom]: 'Sent by',
  [tokens.common.workInProgress]: 'Pending declarations',
  [tokens.common.document]: 'Declaration',
  [tokens.common.classification]: 'Classification',
  [tokens.common.flow]: 'Flow',
  [tokens.common.information]: 'Information',
  [tokens.common.exportConfiguration]: 'Configuration',
  [tokens.common.userSettings]: 'User settings',
  [tokens.common.confirmationRequired]: 'Confirmation required',
  [tokens.common.rowsPerPage]: 'Rows per page',
  [tokens.common.details]: 'Details',
  [tokens.common.declaration]: 'Declaration',
  [tokens.common.backToHome]: 'Back to home',
  [tokens.common.message401]: '401 : Authorization required',
  [tokens.common.message404]: '404: The page you are looking for isn’t here',
  [tokens.common.message500]: '500: Internal Server Error',
  [tokens.common.titleErrorPage]: 'Unknown error',
  [tokens.common.messageErrorPage]:
    'It seems that an unexpected error has occurred, please try again.',
  [tokens.common.key]: 'Key',
  [tokens.common.configurations]: 'Configurations',
  [tokens.common.userSettingsNoParameter]: 'No parameters available',
  [tokens.common.commonError]: 'An error occured',
  [tokens.common.author]: 'Submitter',
  [tokens.common.noFiles]: 'No files available',
  [tokens.common.notification]: 'Notification',
  [tokens.common.createdBy]: 'Created by',
  [tokens.common.sendBy]: 'Send by',
  [tokens.common.updateSucceeded]: 'Update succeeded',
  [tokens.common.updateFailed]: 'Update failed',
  [tokens.common.filesDropInputWrongFormat]: 'Unsupported file type!',
  [tokens.common.hintMessageFirstText]: 'Use',
  [tokens.common.hintMessageSecondText]: 'space',
  [tokens.common.hintMessageThirdText]: 'to add a keyword,',
  [tokens.common.hintMessageFourthText]: 'to search for an exact phrase.',
  [tokens.common.thisActionCannotBeUndone]: 'This action cannot be undone',
  [tokens.common.remove]: 'Remove',
  [tokens.common.unknown]: 'Unknown',

  [tokens.configuration.configurationNameWarning]:
    'Name of configuration is required',
  [tokens.configuration.typeTransportWarning]: 'Type of export is required',
  [tokens.configuration.nameTransportWarning]: 'Name of software is required',
  [tokens.configuration.addressTransportWarning]:
    'Url or ftp address is required',
  [tokens.configuration.portTransportWarning]: 'Port is required',
  [tokens.configuration.emailAuthenticationTransportWarning]:
    'Email for authentication is required',
  [tokens.configuration.passwordAuthenticationTransportWarning]:
    'Password is required',
  [tokens.configuration.configurationSaved]: 'Configuration saved',
  [tokens.configuration.configurationName]: 'Configuration name',
  [tokens.configuration.softwareName]: 'Software name',
  [tokens.configuration.typeTransport]: 'Transport method',
  [tokens.configuration.addressTransport]: 'API URL or SFTP',
  [tokens.configuration.portTransport]: 'Port',
  [tokens.configuration.emailAuthenticationTransport]: 'Email',
  [tokens.configuration.passwordAuthenticationTransport]: 'Password',

  [tokens.upload.dropText]: 'Drop your files here',
  [tokens.upload.declarationType]: 'Declaration type',
  [tokens.upload.exportService]: 'Customs software',
  [tokens.upload.workflow]: 'Workflow',
  [tokens.upload.emailHookText]: 'or send your documents to',
  [tokens.upload.atLeastOneFileRequired]: 'At least one file is required',
  [tokens.upload.warningOnNumberFilesMinimum]: 'At least one file is required',
  [tokens.upload.warningOnNumberFilesMaximum]: 'Only one file is expected',
  [tokens.upload.requiredFiles]: 'Files are required',
  [tokens.upload.requiredCustomerType]: 'Customer type is required',
  [tokens.upload.requiredRecordName]: 'Declaration name is required',
  [tokens.upload.warningOnRecordNameLength]:
    'Record name must be at most 32 characters long',
  [tokens.upload.requiredExportConfiguration]: 'Declarations software required',
  [tokens.upload.requiredDeclarationType]: 'Declaration type required',
  [tokens.upload.requiredFluxConfiguration]: 'Flux required',
  [tokens.upload.requiredGroup]: 'Group is required',

  [tokens.documents.collaborators]: 'Collaborators',
  [tokens.documents.statusExported]: 'Sent',
  [tokens.documents.statusToRead]: 'To Read',
  [tokens.documents.statusToTreat]: 'To Process',
  [tokens.documents.statusToSend]: 'To send',
  [tokens.documents.statusError]: 'Error',
  [tokens.documents.statusSent]: 'Sent',
  [tokens.documents.visualizeDocuments]: 'View documents',
  [tokens.documents.downloadDeclarationXML]:
    'Download the declaration in XML format',
  [tokens.documents.downloadDetailValue]: 'Download the value details',
  [tokens.documents.downloadOrginalDocuments]:
    'Download the original documents',
  [tokens.documents.typeDocument]: 'Document type',
  [tokens.documents.nameDocument]: 'Document name',
  [tokens.documents.statusExtraction]: 'Extraction',
  [tokens.documents.statusInProgress]: 'In Progress',
  [tokens.documents.statusArchived]: 'Archived',
  [tokens.documents.statusUploaded]: 'Uploaded',
  [tokens.documents.statusUnknown]: 'Unknown',
  [tokens.documents.removeRecordTitle]: 'Delete a record',
  [tokens.documents.removeRecordsTitle]: 'Delete some records',
  [tokens.documents.removeRecordText]:
    'Do you really want to delete this record?',
  [tokens.documents.removeRecordsText]:
    'Do you really want to delete those records?',
  [tokens.documents.removePendingRecordTitle]: 'Delete a pending declaration',
  [tokens.documents.removePendingRecordsTitle]:
    'Delete some pending declarations',
  [tokens.documents.removePendingRecordText]:
    'Do you really want to delete this pending declaration?',
  [tokens.documents.removePendingRecordsText]:
    'Do you really want to delete those pending declarations?',
  [tokens.documents.documentHasBeenDeleted]: 'Declaration has been deleted',
  [tokens.documents.documentHasNotBeenDeleted]:
    'Declaration has not been deleted',
  [tokens.documents.documentHasBeenUpdated]: 'Declaration has been updated',
  [tokens.documents.noDocumentFound]: 'No declarations found',

  [tokens.export.confirmExportTitle]: 'Confirm to export',
  [tokens.export.confirmExportText]: 'Do you really want to send this record?',
  [tokens.export.confirmExport]: 'Send',
  [tokens.export.successToExport]: 'The record was successfully sent',
  [tokens.export.notExtracted]: 'Not extracted',

  [tokens.teams.teamName]: 'Name of the team',
  [tokens.teams.removeFromGroup]: 'Remove from Group',
  [tokens.teams.youHaveNoTeam]: 'You do not have a team',
  [tokens.teams.createATeam]: 'Create a team',
  [tokens.teams.noRole]: 'No role',

  [tokens.nav.account]: 'Account',
  [tokens.nav.managementConsole]: 'Management Console',
  [tokens.nav.analytics]: 'Analytics',
  [tokens.nav.auth]: 'Auth',
  [tokens.nav.browse]: 'Browse',
  [tokens.nav.contact]: 'Contact',
  [tokens.nav.create]: 'Create',
  [tokens.nav.customers]: 'Customers',
  [tokens.nav.dashboard]: 'Dashboard',
  [tokens.nav.gobackToDeclaration]: 'Back to current declarations',
  [tokens.nav.backoffice]: 'Backoffice',
  [tokens.nav.database]: 'Database',
  [tokens.nav.customerFlow]: 'Data centers',
  [tokens.nav.customer]: 'Customer',
  [tokens.nav.flow]: 'Flow',
  [tokens.nav.details]: 'Details',
  [tokens.nav.edit]: 'Edit',
  [tokens.nav.error]: 'Error',
  [tokens.nav.feed]: 'Feed',
  [tokens.nav.fileManager]: 'File Manager',
  [tokens.nav.forgotPassword]: 'Forgot Password',
  [tokens.nav.list]: 'List',
  [tokens.nav.login]: 'Login',
  [tokens.nav.mail]: 'Mail',
  [tokens.nav.management]: 'Management',
  [tokens.nav.orderList]: 'Orders',
  [tokens.nav.overview]: 'Overview',
  [tokens.nav.organizations]: 'Organizations',
  [tokens.nav.pages]: 'Pages',
  [tokens.nav.postCreate]: 'Post Create',
  [tokens.nav.postDetails]: 'Post Details',
  [tokens.nav.postList]: 'Post List',
  [tokens.nav.pricing]: 'Pricing',
  [tokens.nav.productList]: 'Products',
  [tokens.nav.fieldList]: 'Declarations',
  [tokens.nav.fieldToRead]: 'To Read',
  [tokens.nav.fieldToManage]: 'To Manage',
  [tokens.nav.pendingField]: 'Pending',
  [tokens.nav.fieldExported]: 'Sent',
  [tokens.nav.fieldDeleted]: 'Deleted',
  [tokens.nav.pendingDocuments]: 'Pending declarations',
  [tokens.nav.sentDocuments]: 'Sent declarations',
  [tokens.nav.removedDocuments]: 'Declaration removed',
  [tokens.nav.profile]: 'Profile',
  [tokens.nav.register]: 'Register',
  [tokens.nav.resetPassword]: 'Reset Password',
  [tokens.nav.connectors]: 'Connectors',
  [tokens.nav.users]: 'Users',

  [tokens.activity.lastActivity]: 'Recent activity',
  [tokens.activity.subTitleLastActivity]: 'Based on the selected period',
  [tokens.activity.seeSentDocuments]: 'See sent declarations',
  [tokens.activity.seeDocumentsToManage]: 'See declarations',
  [tokens.activity.seeDocuments]: 'See declarations',
  [tokens.activity.exportedDocuments]: 'Sent declarations',

  [tokens.dashboard.batchImport]: 'Declarations',
  [tokens.dashboard.pendingRecords]: 'Pending declarations',
  [tokens.dashboard.recordsToManage]: 'Documents to manage',
  [tokens.dashboard.exportedRecords]: 'Sent declarations',
  [tokens.dashboard.seeRecords]: 'See records',
  [tokens.dashboard.seeExportedRecords]: 'See sent records',
  [tokens.dashboard.createOrganization]: 'Create an organization',
  [tokens.dashboard.organizationCreated]:
    'The organization was successfully established',
  [tokens.dashboard.createOffice]: 'Create an office',
  [tokens.dashboard.officeCreated]: 'The office was successfully established',
  [tokens.dashboard.officeDeleted]: 'The office was successfully deleted',
  [tokens.dashboard.userCreated]: 'The user has been successfully created',
  [tokens.dashboard.deleteOrganization]: 'Delete the organization',
  [tokens.dashboard.deleteUser]: 'Remove user',
  [tokens.dashboard.userUpdated]: 'The user has been successfully changed',
  [tokens.dashboard.organizationAndGroups]: 'Organization and Groups',
  [tokens.dashboard.selectGroups]: 'Select groups to affiliate',
  [tokens.dashboard.inviteCollaborators]: 'Invite collaborators',
  [tokens.dashboard.externalCollaborator]: 'External collaborator',
  [tokens.dashboard.removeCollaborator]: 'Remove collaborator',
  [tokens.dashboard.removeOrganizationTitle]: 'Delete an organization',
  [tokens.dashboard.removeOrganizationText]:
    'Do you really want to delete this organization?',
  [tokens.dashboard.removeUserTitle]: 'Delete an user',
  [tokens.dashboard.removeUserText]: 'Do you really want to delete this user?',
  [tokens.dashboard.sendInvitationEmailTitle]: 'Send an invitation',
  [tokens.dashboard.sendInvitationEmailText]:
    'Are you sure you want to send this user an invitation?',
  [tokens.dashboard.manageDocuments]: 'Manage declaration',
  [tokens.dashboard.dropFilesHere]: 'Drop your files here',
  [tokens.dashboard.useNewFolderButton]: 'or use the "New Folder" button',
  [tokens.dashboard.newFolderButton]: 'New declaration',
  [tokens.dashboard.folderInformation]: 'Declaration information',
  [tokens.dashboard.folderName]: 'Folder Name',
  [tokens.dashboard.customer]: 'Customer',
  [tokens.dashboard.exportService]: 'Customs software',
  [tokens.dashboard.typeDeclarationService]: 'Declaration type',
  [tokens.dashboard.addNewCustomer]: 'Add a new customer',
  [tokens.dashboard.customerName]: 'Customer name',
  [tokens.dashboard.createNewCustomer]: 'Create new customer',
  [tokens.dashboard.punctualCustomer]: 'Ponctual Customer',
  [tokens.dashboard.regularCustomer]: 'Regular customer',
  [tokens.dashboard.theEmailWasCopiedToYourClipboard]:
    'The email was copied to your clipboard !',
  [tokens.dashboard.fastTrackFirstText]: 'Fast track',
  [tokens.dashboard.fastTrackSecondText]:
    '(skip the classification step if your only document is an invoice or an export accompanying document)',
  [tokens.dashboard.fastTrackTooltipText]: 'Select a declaration type',
  [tokens.dashboard.isCurrentlyInAnErrorState]: 'is currently in an error state',
  [tokens.dashboard.declarationInErrorState]: 'Declaration in error state',
  [tokens.dashboard.itsLastReportedStateWas]: 'It\'s last reported state was',
  [tokens.dashboard.ourTechnicalTeamHasBeenNotified]: 'Our technical team has been notified',
  [tokens.dashboard.pleaseContactCustomerServiceForFurtherInformation]: 'Please contact customer service for further information',

  [tokens.backoffice.createUser]: 'Create User',
  [tokens.backoffice.deleteUser]: 'User successfully deleted',
  [tokens.backoffice.successSendEmailMessage]:
    'Invitation email sent successfully',
  [tokens.backoffice.users]: 'Users',
  [tokens.backoffice.importLayer]: 'Import a Layer',
  [tokens.backoffice.chooseLayerName]: 'Choose a layer name',
  [tokens.backoffice.selectDocumentType]: 'Document type',
  [tokens.backoffice.onlyFirstPage]: 'Only the first page will be used.',
  [tokens.backoffice.acceptedFormats]: 'Accepted formats',
  [tokens.backoffice.maxSize]: 'Maximum size',
  [tokens.backoffice.exportConfig]: 'Export Configuration',
  [tokens.backoffice.layerNameIsRequired]: 'Layer name is required',
  [tokens.backoffice.documentTypeIsRequired]: 'Document type is required',
  [tokens.backoffice.exportConfigurationIsRequired]:
    'Configuration is required',
  [tokens.backoffice.deleteOrganization]:
    "L'organisation supprimée avec succès",

  [tokens.organizations.organizationsTitle]: 'Organizations',
  [tokens.organizations.organizationTitle]: 'Organization',

  [tokens.extraction.index]: 'Index',
  [tokens.extraction.articles]: 'Articles',
  [tokens.extraction.chooseClassification]: 'Choose a classification',
  [tokens.extraction.exceptions]: 'Exceptions',
  [tokens.extraction.noExceptionsFound]: 'No exceptions found',
  [tokens.extraction.noDescriptionFound]: 'No description found',
  [tokens.extraction.noConditionsFound]: 'No conditions found',
  [tokens.extraction.noClassification]: 'No classification found',
  [tokens.extraction.legalContext]: 'Legal context',
  [tokens.extraction.informations]: 'Informations',
  [tokens.extraction.products]: 'Products',
  [tokens.extraction.editCells]: 'Edit cells',
  [tokens.extraction.editGrid]: 'Edit grid',
  [tokens.extraction.deleteTable]: 'Delete table',
  [tokens.extraction.articlesTable]: 'Articles table',
  [tokens.extraction.anyColumnsToMatchNotPresentWillBeCreated]: 'Any <b>Columns to match</b> not present in the Articles table will be created.',
  [tokens.extraction.whenCopyingARowFromSourceOnlyCopyValuesWillBeCopied]: 'When copying a row from the packing list to the invoice table, only the values for the <b>Columns to copy</b> in the row will be copied.',
  [tokens.extraction.chooseTheTableYouWantToMergeIntoTheMainArticlesTable]: 'Choose the table you want to merge into the Articles table',
  [tokens.extraction.deleteTablePage]: 'Delete table on current page',
  [tokens.extraction.deleteAllTablePages]: 'Delete tables on all pages',
  [tokens.extraction.missingProduct]: 'Missing product',
  [tokens.extraction.certificateCode]: 'Certificate code',
  [tokens.extraction.informationOnCodesAndDuties]: 'Information on codes and duties',
  [tokens.extraction.mergeOptions]: 'Merge options',
  [tokens.extraction.addInformation]: 'Add information',
  [tokens.extraction.addElement]: 'Add element',
  [tokens.extraction.customInformation]: 'Customs information',
  [tokens.extraction.documentInformation]: 'Declaration',
  [tokens.extraction.shippingInformation]: 'Shipping information',
  [tokens.extraction.transactionDetails]: 'Transaction details',
  [tokens.extraction.findField]: 'Find field',
  [tokens.extraction.cellTooSmall]: 'Cell too small',
  [tokens.extraction.cellNotValid]: 'Cell not valid',
  [tokens.extraction.groupByHSCode]: 'Group by HS Code',
  [tokens.extraction.doYouWantToGroupTokensByHSCode]:
    'Do you want to group by HS Code ?',
  [tokens.extraction.yes]: 'Yes',
  [tokens.extraction.no]: 'No',
  [tokens.extraction.addData]: 'Add data',
  [tokens.extraction.addRow]: 'Add row',
  [tokens.extraction.tableRows]: 'Table rows',
  [tokens.extraction.deleteRow]: 'Delete row',
  [tokens.extraction.invoices]: 'Invoices',
  [tokens.extraction['commercial-invoice']]: 'Invoice',
  [tokens.extraction.packings]: 'Packings',
  [tokens.extraction['packing-list']]: 'Packing',
  [tokens.extraction['packing-list-description']]: 'Combined data from all packing lists',
  [tokens.extraction['hscodes-list']]: 'HS Codes',
  [tokens.extraction['hscodes-list-description']]: 'Combined data from all HS Code lists',
  [tokens.extraction['bill-of-lading']]: 'Bill of lading',
  [tokens.extraction['bill-of-lading-description']]: 'Combined data from all bill of lading lists',
  [tokens.extraction['articles-table-description']]: 'Export articles based on commercial invoice',
  [tokens.extraction.createNewFieldHere]: 'Create a new field at this location',
  [tokens.extraction.showOtherFieldsHere]: 'Show other fields at this location',
  [tokens.extraction.convertSelectionToTable]: 'Convert field into a table',
  [tokens.extraction.convertTableToSelection]: 'Convert table into a field',
  [tokens.extraction.mergeTables]: 'Merge',
  [tokens.extraction.sourceTable]: 'Source table',
  [tokens.extraction.columnsToMatch]: 'Columns to match',
  [tokens.extraction.columnsToCopy]: 'Columns to copy',
  [tokens.extraction.columnToMatchExplanation]: 'A row in the packing list table will be copied to a row in the table if they have the same values for every <b>Column to match</b>.',
  [tokens.extraction.extraction]: 'Extraction',
  [tokens.extraction.nothingToShow]: 'Nothing to show',
  [tokens.extraction.table]: 'Table',
  [tokens.extraction.field]: 'Field',
  [tokens.extraction.tables]: 'Tables',
  [tokens.extraction.grids]: 'Grids',
  [tokens.extraction.grid]: 'Grid',
  [tokens.extraction.cells]: 'Cells',
  [tokens.extraction.displayTheTable]: 'Display the table',
  [tokens.extraction.save]: 'Save',
  [tokens.extraction.noOtherFormatsAvailable]: 'No other formats available',
  [tokens.extraction.downloadTestFile]: 'Download test file',
  [tokens.extraction.exportTo]: 'Send to',
  [tokens.extraction.newField]: 'New field',
  [tokens.extraction.convertIntoField]: 'Convert into field',
  [tokens.extraction.convertIntoTable]: 'Convert into table',
  [tokens.extraction.newTable]: 'New table',
  [tokens.extraction.on]: 'out of',
  [tokens.extraction.duplicate]: 'Make a copy',
  [tokens.extraction.completedFields]: 'Completed fields',
  [tokens.extraction.workComplete]: 'Work complete',
  [tokens.extraction.mustBeString]: 'Must be a string',
  [tokens.extraction.mustBeNumber]: 'Must be a number',
  [tokens.extraction.areYouSureYouWantToCopyDeclaration]:
    'Are you sure you want to make a copy of this declaration',
  [tokens.extraction.thereWasAnErrorCopyingTheDeclaration]:
    'There was an error copying the declaration',
  [tokens.extraction.mustMatchFormatDateTime]: 'Must match date / time format',
  [tokens.extraction.mustMatchFormatDate]: 'Must match date format',
  [tokens.extraction.mustNotHaveMoreThanCharacters]:
    'Must NOT have more than {{ count }} characters',
  [tokens.extraction.mustNotHaveFewerThanCharacters]:
    'Must not have fewer than {{ count }} characters',
  [tokens.extraction.pleaseEnterBetweenXAndYCharacters]:
    'Please enter between {{min}} and {{max}} characters.',
  [tokens.extraction.pleaseEnterExactlyXCharacters]:
    'Please enter exactly {{count}} characters.',
  [tokens.extraction.pleaseEnterAWholeNumber]: 'Please enter a whole number',
  [tokens.extraction.updatingData]: 'Updating data',
  [tokens.extraction.mustBeInAlpha2]:
    'Must be in alpha-2 format. For example, France ⇒ FR.',
  [tokens.extraction.doitTreAuFormatAlpha2ExemplePourFranceFr]:
    'Must be in alpha-2 format. For example, France ⇒ FR.',
  [tokens.extraction.pleaseEnterExactly10CharactersInFormat]:
    'Please enter exactly 10 characters in the format DD/MM/YYYY',
  [tokens.extraction.pleaseEnterANumber]: 'Please enter a number',
  [tokens.extraction.pleaseEnterAMaximumOf]:
    'Please enter a maximum of {{count}} characters',
  [tokens.extraction.pleaseSelectFromTheGivenValues]:
    'Please select from the given values',
  [tokens.extraction.pleaseEnterAValueThatConsistsOfLetters]:
    'Please enter a value that consists of letters (uppercase or lowercase), numbers, and hyphens, and has a maximum length of 35 characters',
  [tokens.extraction.pleaseEnterAValidSiretNumber]:
    'Please enter a SIRET number',
  [tokens.extraction['18LettersAndNumbersMaximum']]:
    '18 letters and numbers maximum',
  [tokens.extraction.mustBeInteger]: 'Must be a number',
  [tokens.extraction.loadingExtractionInformation]:
    'Nabu extracts data from your documents',
  [tokens.extraction.loadingBackToDashboard]:
    'You can return to your dashboard in the meantime',
  [tokens.extraction.createTable]: 'Create table',
  [tokens.extraction.deleteColumn]: 'Delete column',
  [tokens.extraction.doYouWantToDeleteTable]:
    'Are you sure you want to delete the table of all pages ? All extracted and manually added data for all tables will be lost.',
  [tokens.extraction.doYouWantToDeleteTableOnCurrentPage]:
    'Are you sure you want to delete the table of this page ? All extracted and manually added data for the table will be lost.',
  [tokens.extraction.editTable]: 'Edit the table',
  [tokens.extraction.editCells]: 'Edit cells',
  [tokens.extraction.editField]: 'Edit field',
  [tokens.extraction.mergeKeysNotSpecificEnough]: 'Multiple merge rows match the same target row',
  [tokens.extraction.deleteTableOnlyInThePage]: 'Delete the table on this page',
  [tokens.extraction.deleteTableInAllPage]: 'Delete table on all pages',
  [tokens.extraction.doYouWantToDeleteColumn]:
    'Are you sure you want to delete this column ? All extracted and manually added data for the column will be lost.',
  [tokens.extraction.doYouWantToDeleteColumnAndDeconsolidate]:
    'Are you sure you want to delete this column? All data in the column - extracted and added manually - will be lost, and items will no longer be consolidated.',
  [tokens.extraction.doYouWantToEditColumnAndDeconsolidate]:
    'Are you sure you want to edit this row? Articles will no longer be consolidated',
  [tokens.extraction.doYouWantToDeleteRow]:
    'Are you sure you want to delete this row ? All extracted and manually added data for the row will be lost.',
  [tokens.extraction.missingValuesInColumn]:
    '{{count}} missing value(s) in the "{{column}}" column to perform this calculation',
  [tokens.extraction.columnMissing]:
    'The values in column "{{column}}" are missing to perform this calculation',
  [tokens.extraction.addOriginOfArticles]: 'the origin of items',
  [tokens.extraction.addHSCodeOfArticles]: 'the HS Code',
  [tokens.extraction.addCodePrefOfArticles]: 'the preferential tariff code',
  [tokens.extraction.addOriginCountryCode]: 'the country of origin code',
  [tokens.extraction.codePreferenceModalTitle]: 'Code Preferences',
  [tokens.extraction.codePreferenceCopy]:
    'The field code has been copied successfully',
  [tokens.extraction.ofItems]: 'of items',
  [tokens.extraction.add]: 'Add',
  [tokens.extraction.emptyTable]: 'The table is empty',
  [tokens.extraction.addToTable]: 'Add to the table',
  [tokens.extraction.addColumn]: 'Add a column',
  [tokens.extraction.declarationState]: 'Declaration status',
  [tokens.extraction.returnToDeclarations]: 'Go back to active declarations',
  [tokens.extraction.updateDeclaration]: 'Update current declaration',
  [tokens.extraction.declarationUploading]: 'Your declaration is loading',
  [tokens.extraction.declarationUploaded]:
    'Your declaration was loaded successfully',
  [tokens.extraction.youWillBeRedirectedToWorkflowCreation]:
    'You will be redirected to the workflow creation in 10 seconds',
  [tokens.extraction.youWillBeRedirectedToTheDashboard]:
    'You will be redirected to the dashboard in 10 seconds',
  [tokens.extraction.declarationUploadFailed]: 'Your declaration upload failed',
  [tokens.extraction.checkDeclarationAndRetry]:
    'Please check your declaration and retry',
  [tokens.extraction.checkDeclaration]: 'Please check your declaration',
  [tokens.extraction.warningOriginX]:
    'Origin x is being moved inside the cells area',
  [tokens.extraction.warningOriginY]:
    'Origin y is being moved inside the cells area',
  [tokens.extraction.warningTouchingCellsOnX]:
    'width is shrinking too much - touching existing cells',
  [tokens.extraction.warningTouchingCellsOnY]:
    'height is shrinking too much - touching existing cells',
  [tokens.extraction.warningBottomRowTooSmall]: 'bottom row is too small',
  [tokens.extraction.warningTopRowTooSmall]: 'top row is too small',
  [tokens.extraction.warningTableLeavesPage]: 'table leaves the page',
  [tokens.extraction.consolidate]: 'Consolidate',
  [tokens.extraction.consolidated]: 'Consolidated',
  [tokens.extraction.deconsolidate]: 'Undo consolidate',
  [tokens.extraction.clickOnTheButtonToUndoConsolidation]: 'The table has been consolidated - click on the button below to undo the consolidation.',
  [tokens.extraction.selectTheColumnsToBeUsedForConsolidation]: 'Select the columns to be used for consolidation',
  [tokens.extraction.sendingConfirmation]: 'Sending confirmation',
  [tokens.extraction.createWorkflowInfo]:
    'This allows you to save the information from this declaration so that you can reuse it. When creating a new folder, simply select a stream from the list to apply the saved settings.',
  [tokens.extraction.sendingFileQuestion]:
    'Are you sure you want to send this declaration?',
  [tokens.extraction.saveWorkflowQuestion]:
    'Would you like to save this workflow?',
  [tokens.extraction.createNewWorkflow]: 'Create a new workflow',
  [tokens.extraction.redirectCreateWorkflow]: 'Redirect to workflow creation',
  [tokens.extraction.fluxFieldsAreFixed]:
    'The fields below are not editable - you can only add or remove fields from a Workflow',
  [tokens.extraction.selectAllFields]: 'Select all fields',
  [tokens.extraction.workflowDetail]: 'Workflow detail',
  [tokens.extraction.workflowCreation]: 'Workflow creation',
  [tokens.extraction.workflowCreated]: 'Workflow created',
  [tokens.extraction.workflowCreationFailed]: 'Workflow creation failed',
  [tokens.extraction.flowApplyChip]: 'No flow applied',
  [tokens.extraction.glideHeaderSum]: 'Sum',
  [tokens.extraction.glideHeaderArticleNumber]: 'Number of items',
  [tokens.extraction.checkFieldModalFirstTextWarning]: 'Warning !',
  [tokens.extraction.checkFieldModalFirstText]:
    'Updating this field will replace all values ​​for "',
  [tokens.extraction.checkFieldModalFirstTextEnding]: '" in your table.',
  [tokens.extraction.checkFieldModalSecondText]:
    'All your current values ​​will be lost!',
  [tokens.extraction.checkFieldModalFieldUpdated]: 'Champs mise à jour !',
  [tokens.extraction.checkFieldModalConsolidatedTitle]:
    'Consolidation of articles',
  [tokens.extraction.checkFieldModalConsolidatedFirstText]:
    'You will send your declaration without having consolidated the articles.',
  [tokens.extraction.checkFieldModalConsolidatedSecondText]:
    'Would you like to modify your declaration or send it without consolidating?',
  [tokens.extraction.checkFieldModalConsolidatedUpdatedButton]:
    'Go back to the declaration',
  [tokens.extraction.checkFieldModalConsolidatedSendButton]:
    'Send without consolidating',
  [tokens.extraction.checkFieldModalDependenciesWarning]: 'Warning !',
  [tokens.extraction.resetLabelBox]: 'Reset',
  [tokens.extraction.productDescription]: 'Product description',
  [tokens.extraction.noAdditionalCodesAvailableForThisTaricCode]:
  'No additional codes available for this TARIC code',
  [tokens.extraction.addAnAdditionalCode]: 'Add an additional code',
  [tokens.extraction.additionalCodes]: 'Additional codes',
  [tokens.extraction.noResult]: 'No result',
  [tokens.extraction.searchByKeywords]: 'Search by keywords',
  [tokens.extraction.currentChoice]: 'Current choice',
  [tokens.extraction.selectItem]: 'Select',
  [tokens.extraction.itemDescription]: 'Article description',
  [tokens.extraction.searchAnotherHsCode]: 'Search another code',
  [tokens.extraction.moreDetails]: '+ details',
  [tokens.extraction.seeLess]: '- details',
  [tokens.extraction.previousRow]: 'Previous row',
  [tokens.extraction.nextRow]: 'Next row',
  [tokens.extraction.row]: 'Row',
  [tokens.extraction.mustBeEqualToOneOfTheAllowedValues]: 'Must be equal to one of the allowed values',
  [tokens.extraction.editDescription]: 'Edit description',
  [tokens.extraction.replaceDescriptionByTaricCodeDescription]: 'Replace description with Taric code description',
  [tokens.extraction.applyValueToAllRows]: 'Apply value to all rows',
  [tokens.extraction.revertToInitialCode]: 'Revert to initial code',
  [tokens.extraction.noAdditionalCodesSelected]: 'No additional codes selected',
  [tokens.extraction.additionalCodesSelected]: '{{count}} additional code selected',
  [tokens.extraction.additionalCodesSelected_plural]: '{{count}} additional codes selected',
  [tokens.extraction.copyValueToEntireColumn]: "Copy this cell's value to the entire column",
  [tokens.extraction.areYouSureYouWantToCopyThisCellValueToAllColumns]: "Are you sure you want to copy this cell's value to all columns in the table ?",
  [tokens.extraction.taricCodeNotFound]: 'TARIC code not found',
  [tokens.extraction.noArticleDescription]: 'No article description available',
  [tokens.extraction.updateDeclarationFlux]: 'Update declaration workflow',
  [tokens.extraction.removeFlux]: 'Remove workflow',
  [tokens.extraction.changeFlux]: 'Change workflow',
  [tokens.extraction.changeFluxForCurrentDeclaration]: 'Change the workflow currently applied to this declaration.',
  [tokens.extraction.applyItemToDeclaration]: 'Apply these codes to the declaration',
  [tokens.extraction.redoClassificationStep]: 'Redo Classification step',
  [tokens.extraction.noDuty]: 'No duty found',
  [tokens.extraction.hoverOverAResultToSeeDetails]: 'Place your mouse over a result to see details',
  [tokens.extraction.searchAdditionalCodesByKeyword]: 'Search additional codes by keyword',
  [tokens.extraction.basicValidations]: 'Basic validations',
  [tokens.extraction.coherenceChecks]: 'Coherence checks',
  [tokens.extraction.sendMessage]: 'Send message',
  [tokens.extraction.newMessage]: 'New message',
  [tokens.extraction.error]: 'Error',
  [tokens.extraction.countries]: 'Countries',
  [tokens.extraction.includedCountries]: 'Included countries',
  [tokens.extraction.excludedCountries]: 'Excluded countries',
  [tokens.extraction.renvois]: 'Legal references',
  [tokens.extraction.referenceAbbrev_tax]: 'TAX',
  [tokens.extraction.referenceAbbrev_certificate]: 'CRT',
  [tokens.extraction.taxCode]: 'Tax code',
  [tokens.extraction.duty]: 'Duty',
  [tokens.extraction.tax]: 'Tax',
  [tokens.extraction.tariffProvision]: 'Tariff provision',
  [tokens.extraction.tariffProvision_short]: 'Tariff-prov',
  [tokens.extraction.legalReferences]: 'Legal references',
  [tokens.extraction.unknown]: 'Unknown',
  [tokens.extraction.consolidationOptions]: 'Consolidation options',

  [tokens.extractionSteps['run-export']]: 'Run export',
  [tokens.extractionSteps['start-work']]: 'Start work',
  [tokens.extractionSteps['publishing-page-messages']]: 'Publishing page messages',
  [tokens.extractionSteps['start-extraction']]: 'Start extraction',
  [tokens.extractionSteps['apply-post-process']]: 'Apply post-process',
  [tokens.extractionSteps['batch-import-status-to-done']]: 'Batch import status to done',
  [tokens.extractionSteps['parsing-ocr']]: 'Parsing OCR',
  [tokens.extractionSteps['get-fast-track-option']]: 'Get fast track option',
  [tokens.extractionSteps['handle-ocr']]: 'Handle OCR',
  [tokens.extractionSteps['collecting-configurations']]: 'Collecting configurations',
  [tokens.extractionSteps['starting-work']]: 'Starting work',
  [tokens.extractionSteps['save-export-data']]: 'Save export data',
  [tokens.extractionSteps['update-batch-import-status']]: 'Update batch import status',
  [tokens.extractionSteps['canceled-iteration']]: 'Canceled iteration',
  [tokens.extractionSteps['classification-done']]: 'Classification done',
  [tokens.extractionSteps['saving-pages']]: 'Saving pages',
  [tokens.extractionSteps['end-validation-work']]: 'End validation work',
  [tokens.extractionSteps['end-mapping-work']]: 'End mapping work',
  [tokens.extractionSteps['start-ocr-work']]: 'Start OCR work',
  [tokens.extractionSteps['create-files-in-database']]: 'Create files ',
  [tokens.extractionSteps['generate-export']]: 'Generate export',
  [tokens.extractionSteps['start-mapping-work']]: 'Start mapping work',
  [tokens.extractionSteps['segmentation-done']]: 'Segmentation',
  [tokens.extractionSteps['apply-segmentations']]: 'Apply segmentations',
  [tokens.extractionSteps['fetching-file']]: 'Fetching file',
  [tokens.extractionSteps['deskew']]: 'Deskew',

  [tokens.flows.sender]: 'Sender',
  [tokens.flows.receiver]: 'Receiver',
  [tokens.flows.flowType]: 'Flow type',
  [tokens.flows.exportContext]: 'Export context',

  [tokens.segmentation.finishSegmentationStep]: 'Finish segmentation step',
  [tokens.segmentation.merge]: 'Merge',
  [tokens.segmentation.cut]: 'Cut',
  [tokens.segmentation.deletePage]: 'Delete page',
  [tokens.segmentation.keep]: 'Keep',

  [tokens.settings.noDocumentsFound]: 'No declaration found',

  [tokens.floatinglabelform.save]: 'Save',
  [tokens.floatinglabelform.apply]: 'Apply',
  [tokens.floatinglabelform.text]: 'Text',

  [tokens.viewer.doYouWantToDeleteTheTable]: 'Do you want to delete the table',
  [tokens.viewer.apply]: 'Apply',
  [tokens.viewer.adjustTheDocument]: 'Adjust the document',
  [tokens.viewer.selectedLabel]: 'Selected label',
  [tokens.viewer.selectADocument]: 'Select a document',
  [tokens.viewer.applyALayer]: 'Apply layer',
  [tokens.viewer.newColumn]: 'New column',
  [tokens.viewer.invoice]: 'Invoice',

  [tokens.classification.layers]: 'Layers',
  [tokens.classification.manualLayer]: 'Manual',
  [tokens.classification.searchClassificationType]:
    'Search for a classification type',
  [tokens.classification.outOf]: 'in',
  [tokens.classification.validate]: 'Validate',
  [tokens.classification.unknownDocument]: 'unknown document',
  [tokens.classification.unknownDocuments]: 'unknown documents',
  [tokens.classification.addExtractionMode]: 'Add extraction mode',
  [tokens.classification.ignoreDocument]: 'Ignore the document',
  [tokens.classification.includeDocument]: 'Include the document',
  [tokens.classification.fieldRequired]: 'Field is required',
  [tokens.classification.selectExtractionMode]: 'Extraction mode',
  [tokens.classification.onlyInvoicesAllowed]:
    'Only invoices or EAD are accepted',
  [tokens.classification.noLeadDocument]:
    'At least one export "lead document" is required',
  [tokens.classification.someDocumentsWithoutClassification]:
    'Some documents have no classification',
  [tokens.classification.hasNoExportConfiguration]:
    'The type of declaration is missing',
  [tokens.classification.document]: '{{count}} document',
  [tokens.classification.document_plural]: '{{count}} documents',
  [tokens.classification.page]: '{{count}} page',
  [tokens.classification.page_plural]: '{{count}} pages',
  [tokens.classification.MT700]: 'MT 700',
  [tokens.classification.weight_note]: 'Weight note',
  [tokens.classification.exchange_bill]: 'Bill of exchange',
  [tokens.classification.origin_certif]: 'Certificate of origin',
  [tokens.classification.multimodal_doc]: 'Multimodal transport document',
  [tokens.classification.flight_letter]: 'Air waybill',
  [tokens.classification.sea_waybill]: 'Sea waybill',
  [tokens.classification.road_document]: 'Road Transport Document',
  [tokens.classification.delivery_note]: 'Delivery note',
  [tokens.classification.insurance_policy]: 'Insurance policy',
  [tokens.classification.bill_of_lading]: 'Bill of lading',
  [tokens.classification.single_administrative_document]: 'SAD',
  [tokens.classification.commercial_invoice]: 'Commercial invoice',
  [tokens.classification.customs_instructions]: 'Customs instructions',
  [tokens.classification.packing_list]: 'Packing list',
  [tokens.classification.hscodes_list]: 'HS Codes list',
  [tokens.classification.electronic_accompanying_document]: 'EAD',
  [tokens.classification.EAD]: 'Export accompaning document',
  [tokens.classification.conversation]: 'Conversation',
  [tokens.classification.MT700_short]: 'MT 700',
  [tokens.classification.weight_note_short]: 'Weight note',
  [tokens.classification.exchange_bill_short]: 'BE',
  [tokens.classification.origin_certif_short]: 'Certificate of origin',
  [tokens.classification.multimodal_doc_short]: 'MTD',
  [tokens.classification.flight_letter_short]: 'Air waybill',
  [tokens.classification.sea_waybill_short]: 'Sea waybill',
  [tokens.classification.road_document_short]: 'RTD',
  [tokens.classification.delivery_note_short]: 'Delivery note',
  [tokens.classification.insurance_policy_short]: 'Insurance policy',
  [tokens.classification.bill_of_lading_short]: 'BL',
  [tokens.classification.single_administrative_document_short]: 'SAD',
  [tokens.classification.commercial_invoice_short]: 'Invoice',
  [tokens.classification.customs_instructions_short]: 'Customs instr.',
  [tokens.classification.packing_list_short]: 'PL',
  [tokens.classification.hscodes_list_short]: 'HS Codes',
  [tokens.classification.electronic_accompanying_document_short]: 'EAD',
  [tokens.classification.EAD_short]: 'EAD',
  [tokens.classification.conversation_short]: 'Conversation',

  [tokens.connector.addConnector]: 'Add connector',

  [tokens.filter.sortBy]: 'Sort By',
  [tokens.filter.searchOrganization]: 'Search an organization',
  [tokens.filter.searchUser]: 'Search an user',
  [tokens.filter.searchLayer]: 'Search a layer',
  [tokens.filter.lastUpdateNewest]: 'Last update (newest)',
  [tokens.filter.lastUpdateOldest]: 'Last update (oldest)',

  [tokens.customerFlow.customer]: 'Client',
  [tokens.customerFlow.flow]: 'Flux',
  [tokens.customerFlow.documentType]: 'Document type',
  [tokens.customerFlow.removeCustomerTitle]: 'Delete a customer',
  [tokens.customerFlow.removeCustomerText]:
    'Do you really want to delete this customer?',
  [tokens.customerFlow.customerHasBeenDeleted]: 'The customer has been deleted',
  [tokens.customerFlow.customerHasNotBeenDeleted]: 'Cannot delete the customer',
  [tokens.customerFlow.removeFlowTitle]: 'Delete a flow',
  [tokens.customerFlow.removeFlowText]:
    'Do you really want to delete this flow?',
  [tokens.customerFlow.flowHasBeenDeleted]: 'The flow has been deleted',
  [tokens.customerFlow.flowHasNotBeenDeleted]: 'Cannot delete the flow',
  [tokens.customerFlow.settingKeyDeleted]: 'The setting key has been deleted',
  [tokens.customerFlow.actionFailed]: 'Action failed',
  [tokens.customerFlow.settingKeyCreated]: 'The setting key has been created',
  [tokens.customerFlow.settingKeyUpdated]: 'The setting key has been updated',
  [tokens.customerFlow.sender]: 'Sender',
  [tokens.customerFlow.flowType]: 'Flow type',
  [tokens.customerFlow.informations]: 'Informations',
  [tokens.customerFlow.documentModels]: 'Document models',
  [tokens.customerFlow.nameOfModel]: 'Name of the model',
  [tokens.customerFlow.issuesSettingsOptions]: 'Error loading keys',
  [tokens.customerFlow.noFluxAvailable]: 'No flow available',
  [tokens.customerFlow.unknownUser]: 'Unknown',

  [tokens.customer.addNew]: 'Add new customer',
  [tokens.customer.address]: 'Address',
  [tokens.customer.addressRequired]: 'Address is required',
  [tokens.customer.basicInformation]: 'Basic informations',
  [tokens.customer.corporateName]: 'Corporate name',
  [tokens.customer.corporateNameRequired]: 'Corporate name is required',
  [tokens.customer.city]: 'City',
  [tokens.customer.cityRequired]: 'City is required',
  [tokens.customer.country]: 'Country',
  [tokens.customer.countryRequired]: 'Country is required',
  [tokens.customer.eoriNumber]: 'EOIR number',
  [tokens.customer.eoriNumberRequired]: 'EOIR number is required',
  [tokens.customer.identifiers]: 'Identifiers',
  [tokens.customer.postalCode]: 'Postal code',
  [tokens.customer.postalCodeRequired]: 'Postal code is required',
  [tokens.customer.rexNumber]: 'REX number',
  [tokens.customer.rexNumberRequired]: 'REX number is required',
  [tokens.customer.siretNumber]: 'SIRET number',
  [tokens.customer.siretNumberRequired]: 'SIRET number is required',
  [tokens.customer.societyType]: 'Society type',
  [tokens.customer.societyTypeRequired]: 'Society type is required',
  [tokens.customer.vatNumber]: 'VAT number',
  [tokens.customer.vatNumberRequired]: 'VAT number is required',
  [tokens.customer.otherSettings]: 'Other settings',
  [tokens.customer.settingValueRequired]: 'A value is required',

  [tokens.managementConsole.title]: 'Management Console',
  [tokens.managementConsole.establishmentsAndGroups]: 'Establishments & Groups',
  [tokens.managementConsole.metrics]: 'Performance monitoring',
  [tokens.managementConsole.member_one]: 'Member',
  [tokens.managementConsole.member_other]: 'Members',
  [tokens.managementConsole.members]: 'Members',
  [tokens.managementConsole.general]: 'General',
  [tokens.managementConsole.organizationSettings]: 'Settings',
  //General section
  [tokens.managementConsole.companyInformation]: 'Company information',
  [tokens.managementConsole.companyNameRequired]: 'Company name required',
  [tokens.managementConsole.registrationNumberRequired]:
    'Registration number required',
  [tokens.managementConsole.registrationCountryRequired]:
    'Registration country required',
  [tokens.managementConsole.registrationNumberWarning]:
    'The registration number must have at least 6 digits',
  [tokens.managementConsole.registrationCountryWarning]:
    'The country must have exactly 3 letters',
  [tokens.managementConsole.organizationUpdated]: 'Company updated',
  [tokens.managementConsole.warningToSaveTitle]:
    'Do you want to save modifications ? ',
  [tokens.managementConsole.warningToSaveConfirmButtonText]: 'Save',
  [tokens.managementConsole.warningToSaveCancelButtonText]:
    'Close tab without saving',
  //OrganizationSettings section
  [tokens.managementConsole.organizationSettingsUpdated]:
    'Company settings updated',
  [tokens.managementConsole.issuesSettingsOptions]: 'Error loading keys',
  [tokens.managementConsole.generalSettings]: 'General settings',
  [tokens.managementConsole.customsPreFilling]:
    'Pre-filling customs declarations',
  [tokens.managementConsole.lockedFieldTooltip]:
    'Information not editable by your teams',
  [tokens.managementConsole.unlockedFieldTooltip]:
    'Information editable by your teams',
  [tokens.managementConsole.establishmentAndGroup]: 'Establisment and group',
  [tokens.managementConsole.administrator]: 'Administator',

  //Establishment
  [tokens.managementConsole.establishment]: 'Establishment',
  [tokens.managementConsole.errorUpdatingSettings]:
    'Error when saving parameters',
  [tokens.managementConsole.resetSettings]: 'The parameters have been reset',
  [tokens.managementConsole.settingsHasBeenUpdated]: 'Settings updated',
  [tokens.managementConsole.impossibleToLockEmptField]:
    'Impossible to lock an empty field',
  [tokens.managementConsole.settingOfGroup]: 'Group setting',

  [tokens.account.generalSettings]: 'General settings',
  [tokens.account.customsPreFilling]: 'Pre-filling customs declarations',
  [tokens.account.settingsHasBeenUpdated]: 'Settings updated',
  [tokens.account.errorUpdatingSettings]: 'Error when saving parameters',
  [tokens.account.resetSettings]: 'The parameters have been reset',

  [tokens.store.anErrorOccurred]: 'An error occurred',
  [tokens.store.unableToUpdateBoundingRectangle]:
    'Unable to update bounding rectangle',
  [tokens.store.targetExportFieldIsNotEmpty]: 'Target field is not empty',
  [tokens.store.unableToCreateColumnSomeCellsAreInvalid]:
    'Unable to create column - some cells are invalid',
  [tokens.store.unableToCreateColumnTableAndColumnRowsMustBeIdentical]:
    'Unable to create column - table and column rows must be identical',
  [tokens.store.thereIsAlreadyATableOnThisPage]:
    'There is already a table on this page',
  [tokens.store.unableToProjectCellsOntoNewTable]:
    'Unable to project cells onto new table',
  [tokens.store.unableToProjectEmptyRowOntoNewPage]:
    'Unable to project empty row onto new page',
  [tokens.store.newTableIsTooSmallToProjectCellsFromExistingTable]:
    'New table is too small to project cells from existing table',
  [tokens.store.tableHasNoRows]: 'Table has no rows',
  [tokens.store.unableToIdentifyTheCurrentRow]:
    'Unable to identify the table row where the mouse is currently placed',
  [tokens.store.cannotDragTableCellIntoADifferentRow]:
    'Cannot move table cell into a different row',
  [tokens.store.tableCellTooSmallOrInverted]:
    'Table cell is too small or has been inverted',
  [tokens.store.tableCellCannotGoOutOfItsRowVertically]:
    "Table cell cannot go out of it's row vertically",
  [tokens.store.tableCellGoesOutOfTableOnTheLeft]:
    'Table cell goes out of table on the left',
  [tokens.store.tableCellGoesOutOfTableOnTheRight]:
    'Table cell goes out of table on the right',
  [tokens.store.tableCellGoesOutOfTableOnTheTop]:
    'Table cell goes out of table on the top',
  [tokens.store.tableCellGoesOutOfTableOnTheBottom]:
    'Table cell goes out of table on the bottom',
  [tokens.store.missingMergeColumnsInSourceTable]:
    'Source table does not have all of the selected merge columns',
}
