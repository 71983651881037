/* eslint-disable */
import SvgIcon from '@mui/material/SvgIcon'

const ConversationFile = (props: any) => {
  return (
    <SvgIcon {...props} viewBox="0 0 150 200" color="inherit" fontSize="large" width={64} height={64}>
      <g opacity="0.3">
        <path
          opacity="0.3"
          d="M98.3278 34.1961C94.4789 34.1924 90.7887 32.6828 88.0672 29.9986C85.3456 27.3144 83.8149 23.6749 83.8112 19.8788V1.29022e-06H18.9518C16.4634 -0.000910743 13.999 0.481709 11.6997 1.42029C9.40042 2.35887 7.31114 3.73501 5.55122 5.47013C3.7913 7.20524 2.39522 9.26534 1.44272 11.5327C0.490221 13.8001 -1.71818e-07 16.2303 0 18.6846V131.32C-1.71818e-07 133.774 0.490221 136.204 1.44272 138.472C2.39522 140.739 3.7913 142.799 5.55122 144.534C7.31114 146.269 9.40042 147.646 11.6997 148.584C13.999 149.523 16.4634 150.005 18.9518 150.004H96.8141C101.839 150.004 106.657 148.036 110.21 144.532C113.763 141.028 115.759 136.275 115.759 131.32V34.1961H98.3278Z"
          fill={props.color || '#00C650'}
        />
      </g>
      <path
        d="M115.8 34H98.3402C94.485 33.9963 90.7888 32.4954 88.0628 29.8266C85.3367 27.1578 83.8035 23.5391 83.7998 19.7649V0L115.8 34Z"
        fill={props.color || '#00C650'}
      />
      <path
        d="M129.457 118H41.1428C34.8783 118 29.7998 123.077 29.7998 129.339V155.661C29.7998 161.923 34.8783 167 41.1428 167H129.457C135.721 167 140.8 161.923 140.8 155.661V129.339C140.8 123.077 135.721 118 129.457 118Z"
        fill={props.color || '#00C650'}
      />
      <path
        d="M83.6782 105H31.9214C27.4408 105 23.7998 100.575 23.7998 95.1278V57.8652C23.7998 52.425 27.4408 48 31.9214 48H83.6782C88.1588 48 91.7998 52.425 91.7998 57.8652V95.1067C91.7998 100.554 88.1588 105 83.6782 105ZM31.9214 53.0691C29.734 53.0691 27.956 55.2256 27.956 57.8863V95.1278C27.956 97.7884 29.734 99.9518 31.9214 99.9518H83.6782C85.8656 99.9518 87.6507 97.7884 87.6507 95.1278V57.8652C87.6507 55.2046 85.8656 53.0481 83.6782 53.0481L31.9214 53.0691Z"
        fill={props.color || '#00C650'}
      />
      <path
        d="M89.7998 82H25.7998V87H89.7998V82Z"
        fill={props.color || '#00C650'}
      />
      <path
        d="M89.7998 65H25.7998V70H89.7998V65Z"
        fill={props.color || '#00C650'}
      />
      <path
        d="M73.7998 51H68.7998V102H73.7998V51Z"
        fill={props.color || '#00C650'}
      />
      <path
        d="M47.7998 51H42.7998V102H47.7998V51Z"
        fill={props.color || '#00C650'}
      />
      <path
        d="M49.7998 148V153H43.7998V148H49.7998Z"
        fill={props.color || 'white'}
      />
      <path
        d="M65.8562 153L61.4899 146.463L57.7434 153H51.9996L58.6248 142.182L51.7998 132H57.7434L61.9927 138.32L65.6497 132H71.3658L64.83 142.573L71.7998 153H65.8562Z"
        fill={props.color || 'white'}
      />
      <path
        d="M78.9528 149.11H85.7998V153H73.7998V132H78.9316L78.9528 149.11Z"
        fill={props.color || 'white'}
      />
      <path
        d="M102.899 149.868C102.289 150.854 101.409 151.644 100.365 152.143C99.0962 152.741 97.7056 153.032 96.3047 152.992C94.1929 153.078 92.112 152.461 90.3845 151.237C89.6231 150.659 89.0002 149.917 88.5617 149.065C88.1231 148.213 87.88 147.274 87.8502 146.315H93.3517C93.3943 147.037 93.7 147.718 94.2106 148.227C94.4633 148.465 94.7601 148.65 95.0841 148.772C95.4081 148.895 95.753 148.952 96.099 148.94C96.6727 148.975 97.2368 148.78 97.6677 148.398C98.0359 148.018 98.246 147.512 98.2552 146.981C98.2645 146.451 98.072 145.937 97.7173 145.545C97.3405 145.166 96.8947 144.863 96.4042 144.653C95.8789 144.42 95.1548 144.142 94.2319 143.818C93.101 143.44 91.996 142.987 90.924 142.463C90.0413 142.007 89.2832 141.341 88.7163 140.523C88.1084 139.518 87.7912 138.364 87.8 137.188C87.8088 136.012 88.1433 134.862 88.766 133.867C89.4333 132.912 90.3597 132.169 91.4351 131.727C92.6758 131.22 94.0076 130.977 95.3465 131.014C97.3783 130.899 99.3864 131.499 101.025 132.712C101.723 133.281 102.294 133.991 102.702 134.796C103.111 135.601 103.347 136.482 103.396 137.384H97.8097C97.7474 136.753 97.4776 136.16 97.043 135.701C96.8202 135.488 96.5568 135.324 96.2688 135.217C95.9808 135.111 95.6743 135.064 95.3678 135.08C94.8372 135.053 94.3156 135.226 93.9054 135.565C93.711 135.745 93.5596 135.967 93.4624 136.214C93.3653 136.461 93.325 136.727 93.3446 136.992C93.3384 137.229 93.3791 137.466 93.4643 137.687C93.5496 137.909 93.6778 138.111 93.8415 138.283C94.1973 138.654 94.6249 138.948 95.0979 139.146C95.6019 139.374 96.326 139.653 97.2702 140.002C98.4206 140.374 99.5407 140.834 100.621 141.379C101.511 141.857 102.277 142.539 102.857 143.369C103.521 144.372 103.849 145.561 103.794 146.765C103.789 147.862 103.479 148.937 102.899 149.868V149.868Z"
        fill={props.color || 'white'}
      />
      <path
        d="M120.559 153L115.974 146.463L112.041 153H106.01L112.966 142.182L105.8 132H112.041L116.502 138.32L120.342 132H126.344L119.482 142.573L126.8 153H120.559Z"
        fill={props.color || 'white'}
      />
    </SvgIcon>
  )
}

export default ConversationFile
